import React from 'react'
import PropTypes from 'prop-types'

const TVBHeader = ({title, subtitle, background, isMedium}) => (
  <section className="section tvb-header" 
  style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: isMedium ? '540px' : '840px'
        }}>
    <h1 className="title is-1 has-text-centered" style={{marginTop: 220, marginBottom: 0}}>{title}</h1>
    <h2 className="title is-2 has-text-centered">{subtitle}</h2>
  </section>
);
export default TVBHeader;

TVBHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    background: PropTypes.string.isRequired,
    isMedium: PropTypes.bool
}